@import '../../../styles/customMediaQueries.css';
.shopContainer {
  position: relative;
  width: 100%;
}

.shopButtons {
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  background-color: #333;
}

.shopButton {
  font-weight: bold;
  font-size: 14px;
  color: white;
  padding: 8px 15px;
  cursor: pointer;
  position: relative;
  /* transition: background-color 0.3s ease, color 0.3s ease; */
  display: inline-block;
  margin-right: 5px;
  border-radius: 3px;
}

.shopButton:hover,
.shopButton.active {
  background-color: var(--marketplaceColor);
  color: white;
  border-radius: 3px;
}

.megaMenu {
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background-color: white;
  padding: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 999;
  width: 100vw;
  border-top: 3px solid var(--marketplaceColor);
}

.visible {
  display: block; /* Make the mega menu visible when active */
}

.menuContent {
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 5px 0;
}

.menuColumn {
  flex: 1;
  padding: 0 15px;
}

.menuColumn h4 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 5px;
  color: #333;
  text-transform: uppercase;
  border-bottom: 2px solid var(--marketplaceColor);
  padding-bottom: 2px;
}

.menuList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.menuList li {
  margin-bottom: 1px;
  cursor: pointer;
  color: #333;
  /* transition: color 0.3s ease; */
}

.menuList li:hover {
  color: var(--marketplaceColor); /* Use the marketplace color variable */
}

.menuList li a {
  text-decoration: none;
  color: inherit;
}

/* Responsive Design */

/* Mobile Specific Styling (max-width: 768px) */
@media screen and (max-width: 768px) {
  .megaMenu {
    position: absolute; /* Fix the menu to prevent scrolling with the page */
    /* top: 100%; 
    left: 0;
    right: 0;
    bottom: 0;  */
    display: none;
    /* top: 0; */
    top: 100%;
    flex-direction: column;
    overflow-y: auto; /* Allow vertical scrolling */
    max-height: calc(100vh - 250px);
    padding: 20px;
    width: 100vw; /* Ensure the menu takes up the full width of the screen */
    box-sizing: border-box; /* Ensure padding and borders are included in width calculations */
  }

  .visible {
    display: block;
  }

  .menuContent {
    flex-direction: column;
    padding: 10px 0;
  }

  .menuColumn {
    padding: 10px;
  }

  /* Prevent body from scrolling when the mega menu is open */
  body.menuOpen {
    overflow: hidden;
  }
}

/* Add styling for the featured brands column */
.featuredBrandsColumn {
  flex: 1;
  padding: 0 15px;
}

.featuredBrandsColumn h4 {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
  text-transform: uppercase;
  border-bottom: 2px solid var(--marketplaceColor);
  padding-bottom: 5px;
}

.brandList {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.brandList a {
  color: #333;
}
.brandList a:hover {
  color: var(--marketplaceColor); /* Change text color on hover */
  text-decoration: none;
}

.brandLogo {
  max-width: 100px;
  max-height: 40px;
  display: inline-block;
}

.brandsDropdown {
  position: absolute;
  top: 100%; /* Positioned right below the "Brands" button */
  left: 0;
  width: 100vw; /* Set a fixed width for the dropdown */
  background-color: #fff;
  padding: 10px;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.1);
  z-index: 999;
  height: 300px; /* Limit the height */
  overflow-y: auto; /* Enable vertical scrolling when the list exceeds max height */
  border: 1px solid #ccc; /* Add a border for a cleaner look */

  @media (--viewportMedium) {
    left: 30px;
    width: 300px; /* Set a fixed width for the dropdown */
  }
}

.brandsList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.brandsList a:hover {
  text-decoration: none;
}

.brandItem {
  padding: 8px 10px;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  transition: background-color 0.1s ease;
}

.brandItem a {
  color: #333;
}

.brandItem:hover {
  background-color: var(--marketplaceColor); /* Highlight the item on hover */
  color: #fff; /* Change text color on hover */
}

.brandItem:hover a {
  color: #fff; /* Change text color on hover */
  text-decoration: none;
}

.brandItem a:hover {
  color: #fff; /* Change text color on hover */
  text-decoration: none;
}
