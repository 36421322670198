.mega-menu {
  display: flex;
  position: relative;
  background-color: #fafafa;
  box-shadow: var(--boxShadowLight);
}

.menu-item {
  margin-right: 20px;
  position: relative; /* Ensure the submenu is positioned relative to the parent */
}

.menu-title {
  /* Basic styling */
  cursor: pointer;
  padding: 10px; /* Increase padding for better click/touch area */
  display: flex;
  align-items: center;
  font-weight: bold;
  /* border: 1px solid #ccc; Add border */
}

.menu-title.active {
  border-bottom: 3px solid var(--marketplaceColor);
}

.menu-title:hover {
  border-bottom: 3px solid var(--marketplaceColor);
}

.submenu {
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  padding: 10px;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px); /* Start slightly above the menu */
  transition: opacity 0.3s ease, visibility 0.3s ease, transform 0.3s ease;
  width: 50vw; /* Set width to 50% of viewport width */
  white-space: nowrap; /* Prevent items from wrapping by default */
}

.menu-item:hover .submenu {
  opacity: 1;
  visibility: visible;
  transform: translateY(0); /* Move to original position */
}

.submenu-container {
  display: flex;
  flex-wrap: wrap; /* Allow items to wrap */
}

.submenu-row {
  flex-grow: 1;
}

.submenu ul {
  list-style-type: none;
  padding: 0;
  margin-right: 0px;
}

.submenu ul li {
  padding: 5px 10px; /* Adjust padding as needed */
  cursor: pointer;
  flex: 0 0 25%; /* Each item takes up 25% of the submenu width by default */
  box-sizing: border-box;
}

/* Style for hover effect */
.submenu ul li:hover {
  border-left: 2px solid var(--marketplaceColor);
  color: var(--marketplaceColor);
}

.submenu-row li {
  font-weight: normal;
  font-size: 16px;
  line-height: 1.5;
}

.submenu-row:first-of-type {
  width: 10px;
}

.submenu-row li.active {
  border-left: 2px solid var(--marketplaceColor);
  color: var(--marketplaceColor);
}

/* Responsive Styles */
@media screen and (max-width: 768px) {
  .menu-item {
    position: initial;
  }
  .submenu {
    width: 100vw;
  }
  .submenu-row {
    justify-content: center;
    flex: 0 0 50%; /* Each item takes up 50% of the width on smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .submenu {
    width: 100vw;
  }
  .submenu-row {
    justify-content: center;
    flex: 0 0 100%; /* Each item takes up 100% of the width on even smaller screens */
  }
}

.brands-scroll-box {
  background-color: white;
  border-radius: 6px;
  padding: 10px;
  width: 100%;
}

.brands-scroll-box-inner {
  overflow-y: scroll;
  max-height: 300px;
}

.brands-scroll-box-inner a {
  color: black;
  text-decoration: none;
}

.brandLink a:hover {
  text-decoration: none;
}
