.announcementBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: sans-serif;
  padding: 12px 24px;
  background: linear-gradient(135deg, #ff4068, #e5245c);
  width: 100%;
  color: white;
  position: relative;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  letter-spacing: 0.02em;
}

.content {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 1.1rem;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.highlight {
  background: rgba(255, 255, 255, 0.2);
  padding: 4px 10px;
  border-radius: 6px;
  font-weight: 600;
  white-space: nowrap;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.link {
  color: white;
  text-decoration: none !important; /* Override any default styles */
  position: relative;
  font-weight: 600;
  padding-bottom: 1px;
  transition: opacity 0.2s ease;
  border-bottom: none !important; /* Ensure no default border */
}

.link:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: white;
  transition: transform 0.2s ease;
  transform: scaleX(0);
  transform-origin: right;
}

.link:hover {
  opacity: 0.9;
}

.link:hover:after {
  transform: scaleX(1);
  transform-origin: left;
}

.timer {
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
  font-weight: 500;
}

.heartIcon {
  animation: pulse 1.5s ease-in-out infinite;
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.1));
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

@media (max-width: 768px) {
  .announcementBar {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 6px 8px;
    gap: 4px;
    justify-content: center;
  }

  .content {
    text-align: center;
    flex-wrap: wrap;
    justify-content: center;
    font-size: 0.85rem;
    gap: 4px;
    line-height: 1.2;
  }

  .highlight {
    padding: 1px 4px;
  }

  .timer {
    font-size: 0.85rem;
  }

  .heartIcon {
    width: 14px;
    height: 14px;
  }
}

@media (max-width: 400px) {
  .announcementBar {
    padding: 4px 6px;
  }

  .content {
    font-size: 0.8rem;
    gap: 3px;
  }

  .timer {
    font-size: 0.8rem;
  }

  .highlight {
    padding: 1px 3px;
  }
}
